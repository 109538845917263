import {
  ControllerParams,
  IHttpClient,
  ControllerFlowAPI,
  IWixWindow,
} from '@wix/yoshi-flow-editor';
import queryString from 'query-string';
import { Checkout, ApiChannelType } from '@wix/ambassador-checkout/http';
import { Checkout as ICheckout } from '@wix/ambassador-checkout/types';
import { makeAutoObservable } from 'mobx';
import APP from '../../.application.json';
import {
  GiftCardProduct,
  GiftCardCatalogOptions,
  IPurchaseOptions,
  GiftCardProductVariant,
} from '../../../../types';

const BASE_URL = '/ecom';
const checkoutService = Checkout(BASE_URL).CheckoutService();

interface CreateCheckoutResponse {
  checkout: ICheckout | undefined;
  redirect_path: string;
}

export class CheckoutService {
  httpClient: IHttpClient;
  instance: string;
  translationsConfig: ControllerFlowAPI['translations']['config'];
  environment: ControllerFlowAPI['environment'];
  bi: ControllerFlowAPI['bi'];
  wixWindow: IWixWindow;

  constructor(controllerParams: ControllerParams) {
    makeAutoObservable(this);
    this.httpClient = controllerParams.flowAPI.httpClient;
    this.instance = controllerParams.controllerConfig.appParams.instance;
    this.translationsConfig = controllerParams.flowAPI.translations.config;
    this.environment = controllerParams.flowAPI.environment;
    this.bi = controllerParams.flowAPI.bi;
    this.wixWindow = controllerParams.controllerConfig.wixCodeApi.window;
  }

  async createCheckout(
    product_id: GiftCardProduct['product_id'],
    purchase_options: GiftCardCatalogOptions,
  ): Promise<CreateCheckoutResponse> {
    const { checkout } = await checkoutService({
      Authorization: this.instance,
    }).createCheckout({
      lineItems: [
        {
          quantity: purchase_options.quantity,
          catalogReference: {
            appId: APP.appDefinitionId,
            catalogItemId: product_id,
            options: purchase_options,
          },
        },
      ],
      channelType: ApiChannelType.WEB,
      checkoutInfo: {},
    });

    const lang = this.translationsConfig.language;
    const defaultLanguage = this.translationsConfig.defaultLanguage;
    const isPrimaryLanguage = lang === defaultLanguage;

    const checkoutUrlParams = {
      lang,
      isPrimaryLanguage,
      a11y: true,
      storeUrl: location.origin,
      plainTextisFastFlow: false,
      plainTextisPickupFlow: false,
      cashierPaymentId: '',
      origin: 'productPage',
      originType: 'buyNow',
      checkoutId: checkout?.id,
    };

    const redirectQuery = queryString.stringify(
      {
        showMobileView: this.environment.isMobile || null,
        appSectionParams: JSON.stringify(checkoutUrlParams),
      },
      {
        skipNull: true,
      },
    );
    const redirect_path = `/checkout?${redirectQuery}`;

    return { checkout, redirect_path };
  }

  reportCheckout = async ({
    productId,
    checkoutId,
    purchase_options,
    price,
    currencyCode,
  }: {
    productId: GiftCardProduct['product_id'];
    checkoutId: ICheckout['id'];
    purchase_options: IPurchaseOptions;
    price: GiftCardProductVariant['price'];
    currencyCode: string;
  }) => {
    if (!this.environment.isSSR) {
      this.bi?.report({
        evid: 20,
        src: 125,
        endpoint: 'gc-client-sf',
        params: {
          productId,
          checkoutId,
          priceOption: price,
          currency: currencyCode,
          hasDeliveryDate: !!purchase_options.deliverAt,
          deliveryDate: purchase_options.deliverAt,
          editorMode: this.environment.isMobile ? 'mobile' : 'desktop',
          quantity: purchase_options.quantity,
          type: 'buy now',
          redirectType: 'checkout',
        },
      });

      this.wixWindow.trackEvent('InitiateCheckout', {
        origin: 'Giftcard',
        contents: [
          {
            price,
            currency: currencyCode,
            quantity: purchase_options.quantity,
          },
        ],
      });
    }
  };

  async getWixCheckoutURL(checkoutId: ICheckout['id']) {
    const checkoutURL = await checkoutService({
      Authorization: this.instance,
    }).getWixCheckoutURL({
      id: checkoutId,
    });
    return checkoutURL;
  }
}
