import { ControllerParams, IHttpClient } from '@wix/yoshi-flow-editor';
import { makeAutoObservable } from 'mobx';
import { GiftCardProduct } from '../../../../types';

interface GetProductResponse {
  gift_card_product: GiftCardProduct;
}

export class GiftCardService {
  httpClient: IHttpClient;
  instance: string;

  constructor(controllerParams: ControllerParams) {
    makeAutoObservable(this);
    this.httpClient = controllerParams.flowAPI.httpClient;
    this.instance = controllerParams.controllerConfig.appParams.instance;
  }

  async getProduct({
    currency,
  }: {
    currency?: string | null;
  }): Promise<GiftCardProduct> {
    const requestUrl = 'https://wix.rise.ai/catalog/templates';
    const requestUrlWithCurrency = `${requestUrl}?currency=${currency}`;

    const getProductRequest = await this.httpClient.request<GetProductResponse>(
      {
        url: 'https://www.wix.com/_serverless/rise-proxy/rise',
        method: 'post',
        headers: {
          Authorization: this.instance,
        },
        disableWixHeaders: true,
        data: {
          riseRequest: {
            url: currency ? requestUrlWithCurrency : requestUrl,
            method: 'get',
            data: {},
          },
        },
      },
    );

    return getProductRequest.data.gift_card_product;
  }

  async getCurrencyCode(): Promise<string> {
    const getCurrencyCodeRequest = await this.httpClient.request<string>({
      url: 'https://www.wix.com/_serverless/rise-proxy/wix',
      method: 'post',
      headers: {
        Authorization: this.instance,
      },
      disableWixHeaders: true,
      data: {
        wixRequest: {
          serviceRequest: {
            entity: 'SettingsReaderService',
            method: 'getCurrency',
            params: {},
          },
        },
      },
    });

    return getCurrencyCodeRequest.data;
  }
}
