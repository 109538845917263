import { ILocation } from '@wix/yoshi-flow-editor';
import { GiftCardProduct } from '../../../../types';
import { AppServices } from '../components/GiftCard/controller/initializeServices';
import { makeAutoObservable } from 'mobx';

export class GiftCardStore {
  loadingProduct: Boolean = false;
  product?: GiftCardProduct;
  readonly locale?: string;
  readonly siteCurrency?: string;
  currencyCode?: string;
  customVariantDisplay?: string;
  readonly location: ILocation;

  constructor(private readonly services: AppServices) {
    makeAutoObservable(this);

    const { wixCodeApi } = services.controllerParams.controllerConfig;
    this.locale = wixCodeApi.site.regionalSettings;
    this.siteCurrency = wixCodeApi.site.currency;
    this.location =
      services.controllerParams.controllerConfig.wixCodeApi.location;
  }

  setCustomVariantDisplay = (foramt: string) => {
    this.customVariantDisplay = foramt;
  };

  init = async () => {
    try {
      const currencyQueryParam = this.location.query.currency;
      this.product = await this.services.giftCardService.getProduct({
        currency: currencyQueryParam,
      });

      this.location.onChange((a: any) => {
        this.init();
      });

      this.currencyCode =
        currencyQueryParam ||
        this.siteCurrency ||
        (await this.services.giftCardService.getCurrencyCode());

      this.loadingProduct = false;
    } catch (error) {}
  };

  toProps() {
    return {
      init: this.init,
      loadingProduct: this.loadingProduct,
      product: this.product,
      locale: this.locale,
      currencyCode: this.currencyCode,
      customVariantDisplay: this.customVariantDisplay,
      setCustomVariantDisplay: this.setCustomVariantDisplay,
    };
  }
}
